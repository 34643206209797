.property {
    position: relative;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.3);
    border-radius: 5px;
    margin: 15px;
    overflow: hidden;

    &__link {
        &:hover {
            .property__plus,{
                cursor: pointer;
                background-color: #E01000;
            }

            .property__plus-background {
                background-color: rgba(224, 15, 0, .3);
            }
        }
    }

    &__image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 250px;
        border-bottom: 2px solid #E01000;

        @media (min-width: 1200px) {
            height: 300px;
        }
    }

    &__info {
        padding: 35px 15px 15px;
        position: relative;
    }

    &__text {
        display: inline-block;
        font-size: 0.8rem;

        &--name {
            @extend .property__text;
            color: $secondary-colour;
            font-weight: 600;
            font-family: Raleway;
            width: 80%;
        }

        &--price {
            @extend .property__text;
            font-family: MuseoSans-900;
            font-weight: 900;
            color: $primary-colour;
            float: right;
            margin-top: 3px;

            @media (min-width: 1200px) {
                margin-top: 0;
            }
        }

        &--location {
            @extend .property__text;
            color: $secondary-colour;
            font-family: MuseoSans-100;
            font-weight: 100;
            width: 70%;
            position: relative;
            top: -4px;
        }

        &--per-month {
            @extend .property__text--price;
            font-family: MuseoSans-100;
            font-weight: 100;
            position: relative;
            top: -4px;
        }
        
        @media (min-width: 1200px) {
            font-size: 1rem;
        }
    }

    &__plus {
        box-shadow: 0px 0px 10px rgba(0,0,0,.5);
        background-color: $primary-colour;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -17.5px;
        margin-top: -17.5px;
        transition: background-color 0.5s;

        &-background {
            background-color: rgba(99, 215, 186, 0.3);;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            position: relative;
            left: 50%;
            margin-left: -30px;
            top: 50%;
            margin-top: -30px;
            transition: background-color 0.5s;
        }

        &-icon {
            color: white;
            position: relative;
            left: 50%;
            margin-left: -20%;
            top: -23px;
        }
    }

    &__status {
        padding: 10px 20px;
        color: white;
        position: absolute;
        top: 20px;
        left: 0;
        font-family: MuseoSans-500;
        font-weight: normal;
        z-index: 1;
        
        &--red {
            @extend .property__status;
            background-color: $light-red;
        }

        &--green {
            @extend .property__status;
            background-color: $primary-colour
        }
        
        &--blue {
            @extend .property__status;
            background-color: $secondary-colour;
        }
    }

    &--margin-bottom {
        @extend .property;
        margin: 15px 15px 30px;
    }
}