@import "include";

#wpadminbar {
    display: none;
}

html, body {
    overflow-x: hidden;
}

#google_language_translator {
    font-weight: bold;
    text-align: right;
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
}