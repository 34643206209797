header {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 47px;
    position: relative;
    z-index: 2;

    @media (min-width: 568px) {
        margin-bottom: 50px;
    }

    @media (min-width: 1200px) {
        margin-bottom: 140px;
    }
}