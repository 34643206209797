.tenants {
    &__image {
        @extend .index__image;
    }

    &__about-us {
        @extend .index__content;

        @media (max-width: 575px) {
           padding-bottom: 0;
        }
    }

    &__text {
        @extend .index__text;

        h1,h2,h3,h4,h5,h6 {
            font-size: 2rem;
        }

        @media (max-width: 991px) {
            margin-bottom: 40px;
        }
    }

    &__property-form {
        select {
            margin-bottom: 20px;
        }

        .more-options {
            display: none;
        }

        .property-search__form {
            padding-top: 30px;
            padding-bottom: 50px;
        }

        @media (max-width: 575px) {
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    &__search {
        max-width: 185px;
    }

    &__map {
        @extend .archive-properties__map;
    }
}