.section {
    &__grey {
        @extend .content;
        background-color: $tertiary-colour;
        text-align: center;

        h2 {
            margin-bottom: 30px;
        }
        
        p {
            display: none;
            margin-bottom: 25px;

            &:first-of-type {
                display: block;
            }

            @media (min-width: 768px) {
                display: block;
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .contact-us {
            height: 50px;
            width: 220px;
            font-size: 1rem;
            line-height: 32px;
            color: $accent-colour;
        }
    }
}