.privacy-policy {
    padding-top: 10px;
    padding-bottom: 20px;

    &__heading {
        color: $secondary-colour;
        font-size: 2.5rem;
        font-weight: normal;
        margin-bottom: 20px;

        @media (min-width: 576px) {
            font-size: 3rem;
        }
    }

    &__sub-heading {
        color: $secondary-colour;
        font-size: 2rem;
        font-weight: normal;
    }

    &__text {
        color: rgba(0,0,0,0.8);
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: 20px;

        p, div {
            @extend .privacy-policy__text;
        }

        h1,h2,h3,h4,h5,h6 {
            @extend .privacy-policy__sub-heading;
        }
    }

    @media (min-width: 768px) {
        padding-top: 20px;
        padding-bottom: 70px;
    }
}