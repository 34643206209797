$primary-colour: #63D7BA;
$bright-green: #02dbb9;

$plus-icon-background-colour: rgba(99, 215, 186, 0.3);

$return-to-top-colour: rgba(224, 16, 1, 0.3);

$secondary-colour: #2e4960;

$accent-colour: #E01000;
$light-red: #f95551;

$search-home-colour: rgba(224, 16, 1, 0.8);

$tertiary-colour: #F7F7F7;