.enquiry-form {
    background: white;
    box-shadow: 0 0px 20px 0px rgba(0,0,0,0.3);
    border-radius: 10px;
    padding-top: 35px;
    padding-bottom: 35px;

    &__header {
        font-size: 1.2rem;
        margin-bottom: 20px;

        @media (min-width: 992px) {
            margin-bottom: 30px;
        }
    }

    &__field {
        margin-top: 10px;
        margin-bottom: 10px;

        &--extra-margin {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &--no-margin {
            margin-bottom: 0;
        }
    }

    &__label {
        font-weight: bold;
        font-size: 0.9rem;

        &--comments {
            @extend .enquiry-form__label;
            float: left;
        }

        &--sign-up {
            @extend .enquiry-form__label;
            margin-left: 40px;
            margin-bottom: 0;
        }
    }

    &__input {
        background-color: $tertiary-colour;
        border: none;
        height: 45px;

        &:focus {
            background-color: $tertiary-colour;
        }
    }

    &__checkbox {
        width: 0;
        cursor: pointer;

        &::before {
            content: '';
            background-color: $tertiary-colour;
            width: 20px;
            height: 20px;
            display: block;
        }

        &:checked {
            &::before {
                content: 'X';
                text-align: center;
            }
        }
        
        @media (max-width: 575px) {
            float: left;
        }
    }

    &__comments {
        @extend .enquiry-form__input;
        resize: none;
        height: 150px;
        width: 100%;
    }

    &__button {
        display: block;
        margin: auto;

        @media (max-width: 575px) {
            margin-bottom: 50px;
        }
    }

    &__contact-btn,
    &__info-pack-btn {
        line-height: 21px;

        @media (max-width: 992px) {
            width: 100%;
        } 
    }

    &__contact-btn {
        @media (max-width: 575px) {
            margin-bottom: 20px;
        }
    }
}

/* Ninja Forms */
.nf-form-fields-required {
    display: none;
}

.nf-form-cont {
    width: 100%;
    padding-top: 15px;
}

div.label-above .nf-field-label {
    width: 130px;
}

div.label-above .nf-field-element, .label-below .nf-field-element {
    width: calc(100% - 130px);

    @media (max-width: 991px) {
        width: 100%;
    }
}

input.nf-field-element input, select.nf-field-element select, textarea.nf-field-element textarea {
    height: 45px;
    margin: auto;
}

#nf-field-4 {
    padding: 10px 25px;

    @media (max-width: 991px) {
        left: auto;
        margin: auto;
    }
}

#nf-field-4-wrap > div.nf-field-label {
    width: 0;
}

#nf-field-4-wrap > div.nf-field-element {
    width: 100%;
}

#nf-field-4 {
    margin: auto;
}

div.nf-field-element input, .nf-field-element select, .nf-field-element textarea {
    height: 45px;
}

#nf-field-6 {
    height: auto;
}