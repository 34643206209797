.background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 250px;
}

.pull-up {
    @media (min-width: 1200px) {
        margin-top: -50px;
    }
}

.no-padding {
    padding: 0;
}

.no-padding-xs {
    @media (max-width: 576px) {
        padding: 0;
    }
}

.content {
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: 992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.no-overflow {
    overflow: hidden;
}