.footer {
    position: relative;
    color: white;
    padding-bottom: 0;

    &__newsletter {
        @extend .content;
        background-color: $secondary-colour;
    }

    &__title {
        margin-bottom: 15px;
        font-weight: 600;
    }

    &__input {
        margin-bottom: 10px;
        border-radius: 100px;
    
        @media (min-width: 768px) {
            width: 98%;
            display: inline-block;
        }
    }

    &__button {
        margin-bottom: 10px;
        line-height: 20px;
        font-weight: bold;
        width: 100%;
        height: 38px;

        @media (min-width: 768px) {
            float: right;
            position: relative;
            width: 185px;
            margin-left: -185px;
        }
    }

    &__logos {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__image {
        display: inline-block;
        max-width: 100%;
        height: auto;
        margin-right: 10px;
    }

    &__address {
        color: #a3acb5;
        margin-bottom: 20px;
        font-weight: 300;
        font-family: MuseoSans-300;
    }

    &__company-name {
        font-weight: normal;
        color: white;
    }

    &__contact-info {
        font-family: MuseoSans-700;
        font-weight: 700;
        margin-bottom: 20px;
    }

    &__tel,
    &__fax,
    &__mailto,
    &__link {
        color: $primary-colour;
        font-weight: inherit;
        font-family: MuseoSans-700;
        font-weight: 700;
        
        &:hover {
            color: $primary-colour;
            text-decoration: none;
        }
    }

    &__copyright {
        background-color: #253a4f;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__copyright-text {
        color: #a3acb5;
        margin-bottom: 5px;

        @media (min-width: 768px) {
            float: left;
        }
    }

    &__website-by {
        @media (min-width: 768px) {
            float: right;
        }
    }

    &__opening-times {
        color: #a3acb5;
    }

    &__heading--white {
        color: white;
        font-weight: normal;
    }
}