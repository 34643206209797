.nav {
    &__background {
        z-index: 99;
        display: none;
        overflow: hidden;
        top: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        background-color: $primary-colour;
    }

    &__menu {
        text-align: left;
        z-index: 100;
        width: 100%;
        position: relative;

        @media (min-width: 1200px) {
            text-align: center;
        }
    }

    &__logo {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;

        &--hide {
            display: none;
        }

        @media (max-width: 767px) {
            width: 150px;
        }

        @media (min-width: 1200px) {
            float: left;
        }
    }

    &__logos {
        display: none;
        margin-top: 40px;
        position: relative;
        z-index: 100;
    }

    &__facebook,
    &__twitter {
        @extend .background-image;
        display: inline-block;
        width: 36px;
        height: 35px;
        margin-right: 10px;
    }

    &__button {
        width: auto;
        float: right;
        position: relative;
        z-index: 100;
        
        &--white {
            position: fixed;
            right: 15px;
        }

        @media (min-width: 768px) {
            margin-top: 10px;
        }

        @media (min-width: 1200px) {
            display: none;
        }
    }

    &__items {
        display: none;
        padding: 15px 0px;
        position: relative;
        margin-bottom: 0;
        margin-top: 25px;
        padding-left: 0;

        &--open { 
            margin-top: 0;
            padding-top: 0;

            .nav__item {
                padding-bottom: 1rem;
            }

            @media (max-width: 1200px) {
                display: inline-block;
            }
        }

        @media (min-width: 1200px) {
            display: flex;
            box-shadow: 0px 0px 10px rgba(0,0,0,.5);
            background-color: $secondary-colour;
        }
    }

    &__item {
        position: relative;
        display: block;
        
        &:hover {
            .nav__sub-menu {
                display: block;
            }
        }

        @media (min-width: 1200px) {
            display: inline-block;
            flex-grow: 1;

            &:last-child {
                display: none;
    
                @media (min-width: 1199px) {
                    display: inline-block;
                }
            }
        }

        &.nav__item--home {
            @media (min-width: 1200px) {
                display: none; 
            }
        }
    }

    &__item-title {
        color: black;
        font-weight: 500;
        font-size: 1.2rem;

        &:hover {
            text-decoration: none;
            cursor: pointer;
            color: black;
        }

        @media (min-width: 1200px) {
            color: white;

            &:hover {
                color: white;
            }
        }
    }

    &__sub-menu {
        background: white;
        display: none;
        position: absolute;
        left: 50%;
        width: 120px;
        margin-left: -60px;
        padding: 0;
        margin-bottom: 0;
        border-radius: 10px;
        box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.3);
        z-index: 1;

        .nav__item {
            margin: auto;
            width: 100%;
            text-align: left;
            padding: 10px;
        }
    }

    &__contact-info {
        display: none;
        position: relative;
        z-index: 100;

        @media (min-width: 1200px) {
            text-align: center;
        }
    }

    &__phone-number {
        display: none;
        float: right;
        margin-top: 8px;
        margin-right: 30px;

        @media (min-width: 1200px) {
            margin-right: 0;
            margin-top: 5px;
            float: none;
            display: block;
            text-align: right;
        }
    }

    &__tel,
    &__fax,
    &__mailto {
        font-size: 14px;
        font-weight: 900;
        color: $secondary-colour;
        display: block;
    }

    &__tel {
        &--big {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 900;
            font-family: museosans-900;

            @media (min-width: 992px) {
                margin-right: 0;
            }
        }
    }

    &__dropdown {
        
    }
}