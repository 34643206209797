.single-property {
    &__top-bar {
        background-color: #F7F7F7;
        padding-top: 20px;
        padding-bottom: 20px;
        
        @media (max-width: 767px) {
            display: none;
        }

        @media (min-width: 1200px) {
            margin-top: -42px;
        }
    }

    &__share {

        &-button {
            width: 100%;
            float: right;
            color: white;
            background-color: $primary-colour;
            
    
            &:hover {
                color: white;
            }
        }

        &-img {
            margin-right: 5px;
        }

        &-text {
            font-family: MuseoSans-700;
            font-size: 0.9rem;
            vertical-align: sub;
        }
    }

    &__back-to-results {
        margin-top: 5px;

        @media (max-width: 576px) {
            margin-bottom: 20px;
        }
    }

    &__back-to-results-text {
        font-size: 1.2rem;
        color: $primary-colour;
        margin-bottom: 0;
        user-select: none;

        &:focus {
            text-decoration: none;
            color: $primary-colour;
        }

        &:hover {
            cursor: pointer;
            color: $primary-colour;
            text-decoration: none;
        }

        svg {
            margin-right: 15px;
            top: 1px;
            position: relative;
        }

        @media (max-width: 575px) {
            margin-bottom: 30px;
        }
    }

    &__background {
        z-index: -1;
        transition: opacity 0.5s;
        background-color: black;
        opacity: 0;
        display: block;

        &.active {
            z-index: 101;
            opacity: 0.5;
        }
    }

    &__image-gallery {
        @media (min-width: 576px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    &__carousel {
        .slick-arrow {
            position: absolute;
            top: 50%;
            margin-top: -17px;
            z-index: 1;
            color: white;

            &__prev {
                left: 15px;
            }

            &__next {
                right: 15px;
            }
        }

        &.active {
            z-index: 101;
            top: 50%;
            margin-bottom: -125px;

            .single-property__property {
                width: 100%;
            }
        }

        @media (max-width: 575px) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    &__image {
        position: relative;

        @media (min-width: 576px) {
            height: 330px;
        }

        @media (min-width: 992px) {
            height: 500px;
        }
    }

    &__image--small {
        height: 125px;
        margin-bottom: 29px;

        @media (min-width: 768px) {
            height: 165px;
        }

        @media (min-width: 992px) {
            height: 147px;
        }
    }

    &__images {
        overflow-y: scroll;
        overflow-x: hidden;

        .col-sm-6:nth-last-child(-n+2) {
            @media (min-width: 576px) {
                .background-image {
                    margin-bottom: 0
                }
            }
        }
        
        .col-sm-6:nth-last-child(-n+3) {
            @media (min-width: 992px) {
                .background-image {
                    margin-bottom: 0
                }
            }
        }

        @media (min-width: 992px) {
            overflow-y: auto;
        }    
    }

    &__info {
        background-color: $tertiary-colour;
        padding-top: 30px;
        padding-bottom: 30px;

        @media (min-width: 576px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @media (min-width: 768px) {
            padding-top: 90px;
            padding-bottom: 90px;
        }
    }

    &__marker {
        padding: 35px 15px 15px;
        position: relative;
        
        &-header {
            font-size: 1.3rem;

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }

        &-price {
            @extend .price-text;
            margin-bottom: 5px;
            font-size: 1.2rem;

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }
        
        &-bedrooms {
            font-size: 1.2rem;

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }

        &-link {
            font-size: 1.2rem;

            @media (max-width: 767px) {
                font-size: 1rem;
            }
        }
    }

    &__price {
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    &__pcm {
        text-transform: uppercase;
        font-weight: 500;
        font-family: MuseoSans-500
    }

    &__description {
        margin-bottom: 0px;

        p {
            &:first-of-type {
                display: block;
            }

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    &__details {
        border-top: 1px solid $primary-colour;
        margin-bottom: 40px;
        
        @media (max-width: 767px) {
            margin-top: 40px;
        }
    }

    &__zoom-in {
        position: absolute;
        background: white;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        font-size: 1rem;
        right: 15px;
        bottom: 15px;

        svg {
            color: black;
            right: -55%;
            margin-left: -10px;
            top: 20%;
            position: relative;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__detail {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $primary-colour;
    }

    &__buttons {
        text-align: center;
        color: white;
    }

    &__view-brochure,
    &__send-enquiry,
    &__view-epc,
    &__floor-plan {
        width: 100%;
        line-height: 22px;
        font-weight: bold;
        height: 38px;
        color: white;

        @media (min-width: 992px) {
            width: 185px;
        }
    }

    &__send-enquiry {
        float: right;

        &--left {
            float: left;
        }
    }

    &__view-brochure {
        float: left;
        
        @media (max-width: 991px) {
            margin-bottom: 10px;
        }
    }

    &__view-epc {
        display: none;

        @media (max-width: 991px) {
            margin-bottom: 10px;
            display: inline-block;
        }

        &--left {
            @extend .single-property__view-epc;
            display: inline-block;

            @media (max-width: 991px) {
                margin-bottom: 10px;
                display: none;
            }
        }
    }

    &__floor-plan {
        display: none;

        @media (max-width: 991px) {
            margin-bottom: 10px;
            display: inline-block;
        }

        &--right {
            @extend .single-property__floor-plan;
            display: inline-block;
            float: right;

            @media (max-width: 991px) {
                margin-bottom: 10px;
                display: none;
            }
        }
    }

    &__label {
        color: $secondary-colour;
        font-weight: bold;
        margin-bottom: 0;

        &--right {
            float: right;
        }
    }

    &__map {
        @extend .archive-properties__map;
    }

    &__modal {
        .modal-body {
            padding: 0;

            .enquiry-form {
                padding: unset;
                box-shadow: none;
            }

            .enquiry-form__header {
                display: none;
            }
        }
    }

    &__epc {
        @media (min-width: 992px) {
            display: none;
        }
    }
}