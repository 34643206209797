.landlords {
    &__form {
        background: white;
        box-shadow: 0 0px 20px 0px rgba(0,0,0,0.3);
        border-radius: 10px;
        padding: 35px 25px;

        &-header {
            font-size: 1.2rem;
        }

        &-field {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        
        &-label {
            font-weight: bold;
            font-size: 0.9rem;
        }

        &-input {
            background-color: $tertiary-colour;
            border: none;
            height: 45px;
        }

        &-checkbox-container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        &-checkbox {
            @extend .landlords__form-input;
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        &-button {
            line-height: 21px;
            display: block;
            margin: auto;
        }

        @media (max-width: 575px) {
            margin-bottom: 50px;
        }
    }

    &__contact-btn,
    &__info-pack-btn {
        line-height: 25px;

        @media (max-width: 992px) {
            width: 100%;
        } 
    }

    &__contact-btn {
        @media (max-width: 575px) {
            margin-bottom: 20px;
        }
    }
}