.front-page {
    &__search {
        height: calc(100vh - 86px);
        position: relative;

        .background-image {
            height: calc(100% - 229px);
            position: relative;
            padding-top: 20px;
    
            .message {
                width: 290px;
    
                h1 {
                    font-size: 24px;
    
                    @media (min-width: 768px) {
                        font-size: 48px;
                        margin-bottom: 25px;
                    }
                }
    
                p {
                    display: none;
    
                    @media (min-width: 768px) {
                        margin-bottom: 25px;
                        display: block;
                    }
                }
    
                .btn {
                    color: $primary-colour;
                    padding: 10px;
                }
    
                @media (min-width: 768px) {
                    width: 570px;
                }
            }
    
            @media (min-width: 768px) {
                height: calc(100% - 175px);
            }
    
            @media (min-width: 992px) {
                padding-top: 130px;
            }
    
            @media (min-width: 1200px) {
                height: 100%;
            }
        }

        @extend .property-search;

        .property-search {
            position: absolute;
            bottom: 0px;
            width: 100%;
        }

        @media (min-width: 1200px) {
            .property-search {
                max-width: 1110px;
                left: 50%;
                margin-left: -555px;
            }
        }

        @media (min-width: 1200px) {
            height: 750px;
        }
    }
    
    &__featured-properties {
        @extend .content;

        .title {
            margin-bottom: 0px;
            
            h2 {
                color: $secondary-colour;   
                display: inline-block;

                @media (max-width: 1200px) {
                    font-size: 19px;
                }
            }
            
            a.see-all {
                margin-bottom: 0;
                float: right;
    
                svg {
                    width: 0.3rem;
                    margin-left: 5px;
                }

                @media (min-width: 1200px) {
                    margin-top: 5px;
                }
            }
        }

        .properties {
            border-radius: 10px;
            margin-top: 10px;
            margin-bottom: 0;

            .slick-arrow {
                position: absolute;
                top: 40%;

                .background {
                    border-radius: 50%;
                    background: $tertiary-colour;
                    width: 50px;
                    height: 50px;                

                    svg {
                        color: $primary-colour;
                        position: absolute;
                        left: 40%;
                        top: 32%;
                    }
                }
                
                &:hover {
                    cursor: pointer;
                }

                &__prev {
                    left: -10%;
                }
    
                &__next {
                    right: -10%;
                }
                
                @media (max-width: 1300px) {
                    &__prev {
                        left: -4%;
                    }

                    &__next {
                        right: -4%;
                    }
                }
            }

            .slick-dots {
                bottom: -25px;
            }

            @extend .property;

            .property {
                @media (min-width: 992px) {
                    margin-right: 20px;
                    margin-left: 20px;
                }
            }

            @media (min-width: 992px) {
                box-shadow: none;
            }
        }

        @media (min-width: 992px) {
            .property {
                margin-top: 20px;
                border-radius: 10px;
                margin-bottom: 15px;
                
                // &--big {
                //     transform: scale(1.1);
                //     margin-bottom: 40px;
                // }
            }
        }

        @media (min-width: 1200px) {
            padding-bottom: 300px;
        }
    }

    &__content {
        @extend .section__grey;
        
        @media (min-width: 1200px) {
            padding-top: 350px;
            padding-bottom: 100px;
        }
    }

    &__featured-properties {
        position: relative;

        &-title {
            @media (min-width: 576px) and (max-width: 991px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &-heading {
            display: inline-block;

            @media (max-width: 1200px) {
                font-size: 1.2rem;
            }
        }

        &-see-all {
            float: right;

            .fa-plus {
                font-size: 0.6rem;
                margin-bottom: 2px;
                margin-left: 5px;
            }

            @media (min-width: 1200px) {
                margin-top: 6px;
            }
        }

        @media (min-width: 768px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @media (min-width: 992px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width: 1200px) {
            padding-top: 100px;
            padding-bottom: 250px;
        }
    }

    &__properties {
        position: relative;
        
        &.slick-initialized {
            margin-bottom: 0;
        }

        .slick-dots {
            bottom: -20px;

            @media (min-width: 768px) {
                bottom: -30px;
            }
        }
    }

    &__arrow {
        width: 12px;
        position: relative;
        left: 50%;
        margin-left: -6px;
        top: 19%;

        &-bg {
            background: $tertiary-colour;
            border-radius: 50%;
            display: inline-block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            margin-top: -20px;

            &--next {
                @extend .front-page__arrow-bg;
                right: -100px;
            }

            &--prev {
                @extend .front-page__arrow-bg;
                left: -100px;
            }

            &:hover {
                cursor: pointer;
            }

            @media (max-width: 1200px) {
                display: none;
            }
        }
    }
}