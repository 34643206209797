.share-icon {
    width: 35px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    transition: transform .1s;
    opacity: 0;
    left: 0;
    top: 3px;
    background-position: center;

    &--facebook {
        background-image: url(../images/social-fb.png);
    }

    &--twitter {
        background-image: url(../images/social-twitter.png);
    }

    &--pinterest {
        background-image: url(../images/pinterest.svg);
    }

    &--email {
        background-image: url(../images/black-back-closed-envelope-shape.png);
        background-color: $primary-colour;
        border-radius: 50%;
        background-size: unset;

    }
    
    &:hover {
        transform: scale(1.2);
    }
}