.archive-properties {
    &__header {
        padding-top: 40px;
        padding-bottom: 40px;

        h2 {
            text-align: left;
            margin-top: 8px;
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 26px;
                margin-bottom: 30px;
                text-align: center;
            }
        }

        .view-buttons {
            width: 100%;
            text-align: center;
            float: left;
            border-radius: 100px;
            border: 1px solid #b9b9b9;

            a {
                display: inline-block;
                font-weight: normal;
                padding-top: 12px;
                padding-bottom: 12px;
                width: 50%;
                cursor: pointer;
                color: black;

                &.view-map {
                    float: left;

                    &.active {
                        color: $primary-colour;
                        font-weight: bold;
                    }

                    &.not-active {
                        color: black;
                        font-weight: normal;
                    }
                }

                &.view-list {
                    float: right;
                    color: $primary-colour;
                    font-weight: bold;
                    border-left: 1px solid #b9b9b9;

                    &.active {
                        color: $primary-colour;
                        font-weight: bold;
                    }

                    &.not-active {
                        color: black;
                        font-weight: normal;
                    }
                }
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        .btn {
            @media (max-width: 767px) {
                &.prev {
                    width: 50%;
                }

                &.next {
                    width: 40%;
                }
            }
        }
    }

    &__properties {
        padding-bottom: 30px;
    }

    &__map {
        height: 400px;

        @media (min-width: 768px) {
            height: 500px;
        }

        @media (min-width: 992px) {
            height: 800px;
        }
    }

    &__tenants {
        @extend .section__grey;
    }

    &__search {
        .more-options {
            margin-top: 7.75px;
        }
    }
}