@font-face {
    font-family: MuseoSans-100;
    font-weight: 100;
    src: url('../fonts/exljbris - MuseoSans-100.otf');
}

@font-face {
    font-family: MuseoSans-300;
    font-weight: 300;
    src: url('../fonts/exljbris - MuseoSans-300.otf');
}

@font-face {
    font-family: MuseoSans-500;
    font-weight: 500;
    src: url('../fonts/exljbris - MuseoSans-500.otf');
}

@font-face {
    font-family: MuseoSans-700;
    font-weight: 700;
    src: url('../fonts/exljbris - MuseoSans-700.otf');
}

@font-face {
    font-family: MuseoSans-900;
    font-weight: 900;
    src: url('../fonts/exljbris - MuseoSans-900.otf');
}