.about-us {
    @extend .content;
    background-color: $tertiary-colour;

    &__image {
        @extend .index__image;
    }

    &__content {
        @extend .section__grey;
        
        &-image {
            @extend .index__content__image;
        }

        &--tall {
            @media (min-width: 992px) {
                padding-bottom: 250px;
            }
        }

        @media (max-width: 767px) {
            padding-bottom: 0;
        }
    }

    &__text {
        @extend .index__text;
        text-align: center;

        p {
            padding-left: 0;
        }
    }

    &__button {
        margin: auto;
    }
}