.quotes {
    @extend .content;
    z-index: 1;
    font-style: italic;
    text-align: center;
    background-color: $primary-colour;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
    position: relative;
    max-width: 1110px;
    margin: auto;

    &__inner {
        .slick-arrow {
            position: absolute;
            top: 40%;

            &:hover {
                cursor: pointer;
            }

            &__prev {
                left: -10%;
            }

            &__next {
                right: -10%;
            }

            @media (max-width: 1300px) {
                &__prev {
                    left: -4%;
                }

                &__next {
                    right: -4%;
                }
            }
        }

        .quote {
            p,
            h6 {
                color: white;
            }

            p {
                padding-right: 15px;
                padding-left: 15px;
                margin-bottom: 20px;

                @media (min-width: 992px) {
                    line-height: 30px;
                    font-size: 24px;
                    padding-right: 90px;
                    padding-left: 85px;
                }
            }

            h6 {
                font-weight: bold;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

    @media (min-width: 1200px) {
        margin-top: -140px;
        margin-bottom: -190px;
        border-radius: 10px;
        height: 280px;
    }
}