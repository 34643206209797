body {
    font-family: 'Raleway', sans-serif;
}

h1 {
    font-size: 48px;
    color: $secondary-colour;
    letter-spacing: 0;
}

h2 {
    @extend h1;
    font-size: 28px;
}

h3 {
    @extend h1;
    font-weight: bold;
    font-size: 18px;
}

h5 {
    font-size: 1rem;
}

p {
    font-size: 16px;
    color: rgba(0,0,0,0.80);
    letter-spacing: 0;
    line-height: 22px;
}

a {
    color: $accent-colour;
    font-weight: bold;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.btn {
    text-transform: uppercase;
    border-radius: 100px;
    color: #FFFFFF;
    height: 40px;   
    width: 185px;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    transition: background-color .3s;
    
    &--primary {
        color: white;
        background-color: $primary-colour;
        font-weight: 600;

        &:hover {
            color: white;
        }
    }

    &--secondary {
        background-color: #FFFFFF;
    }

    &--blue {
        color: white;
        background-color: $secondary-colour;
        
        &:hover,
        &:focus {
            color: white;
        }
    }

    &--white {
        background-color: white;
        font-weight: bold;
        color: $accent-colour;

        &:hover {
            color: red;
        }
    }

    &--load-more {
        margin-top: 40px;
        line-height: 2;
        
        &.prev {
            float: left;
        }

        &.next {
            float: right;
        }

        &:hover {
            color: white;
        }
    }

    &--disabled {
        background-color: rgba(255 , 255, 255 , .6);
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        cursor: pointer;
    }
}

.load-more {
    font-family: Raleway;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 1.88px;
}

.contact-us {
    @extend .load-more;
    color: $accent-colour;
}

.price-text {
    font-family: MuseoSans-900;
    font-weight: 900;
    color: $primary-colour;
}