.team-member {
    padding-top: 30px;
    padding-bottom: 30px;

    &__content {
        margin-top: 20px;
    }

    &__image {
        width: 100%;
    }

    &__name {
        font-size: 1.4rem;
        color: $secondary-colour;

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }

    &__job-title {
        font-size: 1.2rem;
        color: $secondary-colour;

        @media (min-width: 768px) {
            font-size: 1.4rem;
        }
    }

    &__bio {
        margin-top: 10px;
    }

    @media (min-width: 768px) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}