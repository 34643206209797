.contact-us {
    &__content {
        @extend .index__content;
        padding-top: 50px;
        padding-bottom: 100px;

        @media (max-width: 575px) {
            padding-bottom: 0px;
        }
    }

    &__send-enquiry {
        .enquiry-form {
            &__button {
                @media (min-width: 992px) {
                    float: right;
                }
            }

            &__label {
                margin-bottom: 0;
                margin-top: 10px;

                @media (max-width: 575px) {
                    margin-top: 0;
                    float: left;
                }
            }

            @media (min-width: 992px) {
                padding-bottom: 20px;

                &__input,
                &__field--extra-margin {
                    width: 100;
                    float: right;
                }
            }

            @media (min-width: 1200px) {
                padding-right: 100px;
                padding-left: 15px;
            }
        }

        @media (max-width: 575px) {
            margin-bottom: 60px;
        }
    }

    &__address {
        display: none;
        
        .footer {
            &__company-name {
                color: black;
                font-weight: bold;
            }

            &__address {
                color: grey;
            }
        }

        @media (min-width: 768px) {
            display: block;
        }
    }

    &__text {
        text-align: center;
        max-width: 800px;
        margin: auto;

        h3 {
            font-size: 1.4rem;
            margin: 15px;
        }
    }

    &__btn {
        margin: auto;
    }
}