.not-found {
    padding-top: 10px;
    padding-bottom: 40px;

    &__heading {

    }

    &__text {

    }

    &__links {
        list-style: none;
        padding: 0;
        margin-top: 15px;
        margin-bottom: 0;

        &-item {

        }
    }

    &__link {

    }

    @media (min-width: 576px) {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    @media (min-width: 768px) {
        padding-top: 50px;
        padding-bottom: 80px;
    }
}