.property-search {
    &__input {
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: $search-home-colour;

        label {
            font-weight: bold;
        }

        .form-control {
            @media (min-width: 768px) {
                display: inline-block;
                width: auto;
                margin-left: 10px;
            }
        }

        .more-options {
            float: right;

            svg {
                width: 0.5rem;
                margin-left: 5px;
            }
        }

        h5 {
            margin-bottom: 0;

            @media (max-width: 575px) {
                text-align: center;
            }
        }

        a {
            color: white;
            font-weight: 600;
        }
        
        svg {
            width: 0.7em;
            margin-left: 10px;
        }
    }

    &__form {
        background-color: $secondary-colour;
        padding-top: 15px;
        padding-bottom: 15px;
        color: white;

        select {
            margin-bottom: 20px;
        }

        label {
            display: block;
            margin-bottom: 0;
        }

        .price {
            margin-top: 10px;
        }

        label {
            font-weight: normal;
        }

        .slider {
            width: 100%;
        }

        .slider-track-low,
        .slider-track-high {
            border: 1px solid white;
            background: $secondary-colour;
        }

        .slider-handle {
            background: $primary-colour;
            width: 24px;
            height: 24px;
            top: -2px;
        }

        .from,
        .to {
            font-weight: bold;
        }

        .from {
            display: inline-block;
        }

        .to {
            float: right;
        }

        .search-button {
            text-align: center;
            margin-top: 15px;

            button {
                box-shadow: 0 0px 10px 0 rgba(0,0,0,0.6);
                width: 100%;
            }

            @media (min-width: 768px) {
                margin-top: 27px;
            }
        }

        &--grey {
            background-color: $tertiary-colour;
            color: $secondary-colour;

            .slider-track-low,
            .slider-track-high {
                background-color: $tertiary-colour;
                border-color: $secondary-colour;
            }

            .slider-selection {
                background: $secondary-colour;
                border-color: $tertiary-colour;
            }

            @media (min-width: 1200px) {
                padding-top: 25px;
            }
        }
    }

    &__sort-by {
        margin-top: 8px;

        &-label {
            margin-bottom: 0;
            margin-top: 5px;
            font-weight: bold;
        }

        @media (max-width: 991px) {
            margin-top: 30px;
        }

        @media (min-width: 992px) {
            text-align: right;
        }
    }

    &__price-select {
        display: inline-block;
        margin-left: 5px;
        max-width: 200px;
    }
}