.return-to-top {
    display: none;
    width: 50px;
    height: 50px;
    color: red;
    border-radius: 100%;
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 102;

    svg {
        font-size: 2.6rem;
        background-color: white;
        position: absolute;
        left: 50%;
        margin-left: -20.795px;
        top: 50%;
        margin-top: -20.795px;
        border-radius: 100%;
    }

    &.active {
        display: block;
    }

    &:hover {
        cursor: pointer;
    }

    @media (min-width: 567px) {
        right: 30px;
        bottom: 30px;
    }
    
    &__text {
        text-transform: uppercase;
        font-size: 0.8rem; 
        position: absolute;
        left: -110px;
        top: 16px; 
        font-weight: 900;
        font-family: MuseoSans-900;
        letter-spacing: 0.1rem;
        user-select: none;
    }
}