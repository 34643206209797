.meet-the-team {
    @extend .content;

    &__title {
        text-align: center;
        margin-bottom: 30px;
    }

    &__member {
        box-shadow: 0px 0px 25px rgba(0,0,0,0.3);
        border-radius: 5px;
        margin: 15px;
        overflow: hidden;
    }

    &__image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 250px;
    }

    &__info {
        padding: 15px;
    }

    &__name {
        color: $secondary-colour;
        font-size: 1rem;
        font-weight: bold;
        display: block;
    }

    &__job-title {
        display: block;
        color: $secondary-colour;
        font-size: 0.9rem;
        font-weight: 400;
    }

    @media (min-width: 1200px) {
        padding-bottom: 240px;
    }
}