.index {

    &__heading {
        font-size: 1.5rem;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    &__image {
        @extend .background-image;
        height: 350px;

        @media (max-width: 767px) {
            height: 200px;
        }
    }

    &__content {
        padding-top: 60px;
        padding-bottom: 60px;
        background-color: $tertiary-colour;

        &__image {
            @extend .background-image;
            height: 100%;

            @media (max-width: 575px) {
                margin-left: -15px;
                margin-right: -15px;
            }
            
            @media (max-width: 767px) {
                height: 300px;
            }
        }

        @media (min-width: 767px) {
            padding-top: 30px;
            padding-bottom: 90px;
        }

        @media (min-width: 992px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width: 1200px) {
            padding-top: 70px;
            padding-bottom: 240px;
        }
    }

    &__text {
        h1,h2,h3,h4,h5,h6 {
            font-size: 2rem;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 20px;
        }

        @media (max-width: 767px) {
            text-align: center;
            margin-bottom: 40px;
        }

        @media (min-width: 768px) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    &__contact-btn {
        @extend .contact-us;
        margin: 40px auto 0;
        display: block;

        @media (min-width: 767px) {
            margin-left: 0;
        }
    }

    &__map {
        @extend .archive-properties__map;
    }
}